<template>
  <div>
    <v-card
      v-if="!hasAccess"
    >
      <v-card-title class="text-h5 mb-2 pb-0">
        {{ displayName }}
        <v-spacer />
        <v-tooltip
          v-if="infoDescription"
          top
          z-index="15"
        >
          <template v-slot:activator="{ on }">
            <v-icon
              color="info"
              v-on="on"
            >
              mdi-information
            </v-icon>
          </template>
          <span>{{ infoDescription }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        You do not have "Reporting - KPI Widgets permissions"
      </v-card-text>
    </v-card>
    <v-card
      v-else-if="dates.length === 2 && !resultsLoading"
    >
      <v-card-title class="text-h5 mb-2 pb-0">
        {{ displayName }}
        <v-spacer />
        <v-tooltip
          v-if="infoDescription"
          top
          z-index="15"
        >
          <template v-slot:activator="{ on }">
            <v-icon
              color="info"
              v-on="on"
            >
              mdi-information
            </v-icon>
          </template>
          <span>{{ infoDescription }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-row
          v-if="results.length > 0"
        >
          <v-col
            cols="auto"
            class="pr-0"
          >
            <v-progress-circular
              :rotate="-90"
              :size="100"
              :width="15"
              :value="results[0].rate"
              color="#98EB108A"
            >
              <v-icon
                size="52"
                color="black"
              >
                {{ results[0].icon || icon }}
              </v-icon>
            </v-progress-circular>
          </v-col>
          <v-col>
            <p
              class="text-h5 text--primary mb-2 ml-2"
            >
              {{ results[0].name }} ({{ results[0].rate }}%)
            </p>
            <p
              v-if="iconTwo && infoDescriptionTwo && results.length > 1"
              class="subtitle-1 ml-2 mb-1"
            >
              <v-tooltip
                top
                z-index="15"
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    left
                    size="24"
                    v-on="on"
                  >
                    {{ results[1].icon || iconTwo }}
                  </v-icon>
                </template>
                <span>{{ infoDescriptionTwo }}</span>
              </v-tooltip>
              {{ results[1].name }} ({{ results[1].rate }}%)
            </p>
            <p
              v-if="iconThree && infoDescriptionThree && results.length > 2"
              class="subtitle-1 ml-2 mb-1"
            >
              <v-tooltip
                top
                z-index="15"
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    left
                    size="24"
                    v-on="on"
                  >
                    {{ results[2].icon || iconThree }}
                  </v-icon>
                </template>
                <span>{{ infoDescriptionThree }}</span>
              </v-tooltip>
              {{ results[2].name }} ({{ results[2].rate }}%)
            </p>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12">
            <p
              class="text-h6 text--primary mb-2"
            >
              No data
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="flex-row-reverse pt-1">
        <v-btn
          color="primary"
          @click="$emit('viewDetails', name)"
        >
          View
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-skeleton-loader
      v-else-if="resultsLoading"
      type="image"
    />
    <v-card v-else>
      <v-card-title class="text-h5 mb-2 pb-0">
        {{ displayName }}
        <v-spacer />
        <v-tooltip
          v-if="infoDescription"
          top
          z-index="15"
        >
          <template v-slot:activator="{ on }">
            <v-icon
              color="info"
              v-on="on"
            >
              mdi-information
            </v-icon>
          </template>
          <span>{{ infoDescription }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        No data
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  inputMonthFormat,
  displayDateFormat,
  displayMonthFormat,
  inputDateFormat,
  calendarEventFormat,
  displayTimeFormat2
} from '@/shared/constants'
import mutationTypes from '@/store/mutation-types'
import { mailingService } from '@/shared/services'
import claims from '@/shared/models/auth/claims'
import moment from 'moment'

export default {
  name: 'StringMetricsWidget',

  props: {
    displayName: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    dates: {
      type: Array,
      default: () => []
    },
    mailingServiceMethod: {
      type: String,
      default: 'getReportMailClientsWidget'
    },
    icon: {
      type: String,
      default: 'mdi-email'
    },
    iconTwo: {
      type: String,
      default: ''
    },
    iconThree: {
      type: String,
      default: ''
    },
    infoDescription: {
      type: String,
      default: ''
    },
    infoDescriptionTwo: {
      type: String,
      default: ''
    },
    infoDescriptionThree: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      resultsLoading: false,
      results: [],
      unsubscribe: null
    }
  },

  computed: {
    hasAccess () {
      return this.$store.getters['auth/hasClaimKV'](claims.R_KPIW)
    }
  },

  watch: {
    dates (newValue, oldValue) {
      if (newValue.length === 2) {
        this.getMetricData()
      }
    }
  },

  created () {
    this.getMetricData()
  },

  mounted () {
    this.unsubscribe = this.$store.subscribe(mutation => {
      let listeners = [
        mutationTypes.SET_VIEW_AS_CUSTOMER
      ]
      if (listeners.includes(mutation.type)) {
        this.getMetricData()
      }
    })
  },

  destroyed () {
    this.unsubscribe()
  },

  methods: {
    async getMetricData () {
      if (this.dates.length !== 2 || this.$store.getters['isSimulatedMMS']()) return
      var _this = this
      this.resultsLoading = true
      await mailingService[this.mailingServiceMethod]({
        customerNowwId: this.$store.getters['simulatedCustomerNowwId'](),
        metricName: this.name,
        startDate: moment(this.dates[0]).startOf('month').format(inputDateFormat),
        endDate: moment(this.dates[1]).endOf('month').format(inputDateFormat)
      }).then(function (resp) {
        if (resp.errorResponse) {
          _this.$store.commit('setGlobalSnackbar', {
            message: resp.errorResponse,
            color: 'error'
          })
        } else {
          _this.results = resp
        }
      })
        .finally(() => {
          this.resultsLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
